<template>
  <b-navbar class="topbar" toggleable="lg" type="dark" v-if="authorized">
    <b-navbar-brand>
      <img class="navbar-logo" src="@/assets/empresa.svg" alt="GIA Repse">
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse" v-if="authorized"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav v-if="authorized">
      <!-- Right aligned nav items -->
      <b-navbar-nav class="items-menu ml-auto">
        <b-nav-item href="#" @click="gotoInicio">{{ es_proveedor ? 'Perfil' : 'Dashboard' }}</b-nav-item>
        <b-nav-item-dropdown text="Administrar" right  v-if="!es_proveedor && ($ver(['Usuarios']) || $ver(['Perfiles']) || $ver(['Permisos']) || $ver(['Correos autorizados']) || $ver(['Empresas']))">
          <b-dropdown-item href="#" @click="gotoUsuarios" v-if="$ver(['Usuarios'])">
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'users'}"/> Usuarios</b-dropdown-item>
          <b-dropdown-item href="#" @click="gotoProveedores" v-if="$ver(['Usuarios Proveedores'])">
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'address-book'}"/> Proveedores</b-dropdown-item>
          <b-dropdown-item href="#" @click="gotoPerfiles" v-if="$ver(['Perfiles'])">
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'user-tag'}"/> Perfiles</b-dropdown-item>
          <b-dropdown-item href="#" @click="gotoPermisos" v-if="$ver(['Permisos'])">
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'user-lock'}"/> Permisos</b-dropdown-item>
          <b-dropdown-item href="#" @click="gotoEmpresas" v-if="$ver(['Empresas'])">
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'building'}"/> Empresas</b-dropdown-item>
          <b-dropdown-item href="#" @click="gotoCorreosAut" v-if="$ver(['Correos autorizados'])">
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'envelope-square'}"/> Correos autorizados</b-dropdown-item>
          <b-dropdown-item href="#" @click="gotoConfiguraciones" v-if="$ver(['Configuraciones'])">
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'gears'}"/> Configuraciones</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item href="#" v-if="$ver(['Bitacora']) && !es_proveedor" @click="gotoBitacora">{{ 'Bitacora' }}</b-nav-item>
        <!-- <b-nav-item href="#" @click="gotoOrdenes" v-if="es_proveedor && es_repse && $ver(['Ordenes'])">Ordenes</b-nav-item> -->
        <b-nav-item href="#" @click="logout"><font-awesome-icon :icon="{ prefix: 'fa', iconName: 'power-off'}"/> Cerrar sesión</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>
<script>
export default {
  props: [
    'authorized',
    'es_proveedor',
    'es_repse',
  ],
  methods: {
    gotoInicio() {
      if (this.es_proveedor) this.$router.push({ path: '/perfil' }).catch(() => {});
      else this.$router.push({ path: '/' }).catch(() => {});
    },
    gotoUsuarios() {
      this.$router.push({ path: '/usuarios' }).catch(() => {});
    },
    gotoProveedores() {
      this.$router.push({ path: '/usuarios-proveedores' }).catch(() => {});
    },
    gotoPerfiles() {
      this.$router.push({ path: '/perfiles' }).catch(() => {});
    },
    gotoPermisos() {
      this.$router.push({ path: '/permisos' }).catch(() => {});
    },
    // gotoOrdenes() {
    //   this.$router.push({ path: '/ordenes' }).catch(() => {});
    // },
    gotoEmpresas() {
      this.$router.push({ path: '/empresas' }).catch(() => {});
    },
    gotoCorreosAut() {
      this.$router.push({ path: '/correos-aut' }).catch(() => {});
    },
    gotoConfiguraciones() {
      this.$router.push({ path: '/configuraciones' }).catch(() => {});
    },
    gotoBitacora() {
      this.$router.push({ path: '/bitacora' }).catch(() => {});
    },
    logout() {
      this.$store.dispatch('user/LOGOUT');
    },
  },
};
</script>
<style lang="scss" scoped>
// @font-face {
//   font-family: "BankGothic";
//   src: local("BankGothic"),
//    url(../assets/fonts/BankGothicMediumBT.ttf) format("truetype");
// }
.topbar {
  background: #151413;
  padding: 0.2rem 1rem;
}
.items-menu {
  text-align: center;
}
.navbar-brand {
  margin-right: 0;
}
.navbar-logo {
  width: 85%;
}
// .navbar-title {
//   font-family: "BankGothic", Helvetica, Arial;
//   font-size: 1.3rem;
//   font-weight: bold;
//   vertical-align: bottom;
//   margin-bottom: 0;
//   color: white;
//   line-height: normal;
// }
// .navbar-uptitle {
//   font-family: "BankGothic", Helvetica, Arial;
//   font-size: 1.3rem;
//   font-weight: bold;
//   vertical-align: bottom;
// }
</style>
