<template>
<footer class="footer" role="contentinfo">
  <div class="container">
      <div class="row">
          <div class="col-12 col-lg-8">
              <div class="row">
                  <br>
                  <div class="col-12 col-md-3">
                      <img src="@/assets/empresa-light.svg" title="GIA" alt="GIA" class="img-logo-footer">
                      <div class="text-center">v{{ applicationVersion }}</div>
                  </div>
                  <div class="col-12 col-md-3">
                      <p class="call-footer"><a href="#">Teléfonos</a> <br> <a href="tel:+528333755300"> 833-375-5300 Ext: 124</a></p>
                  </div>
                  <div class="col-12 col-md-3">
                      <p class="correo-footer"><a href="mailto:proveedores@giaguila.com.mx">Correo Electrónico</a> <br> <a href="mailto:proveedores@giaguila.com.mx"> proveedores@giaguila.com.mx</a></p>
                  </div>
                  <div class="col-12 col-md-3">
                    <p class="call-footer titulo-aviso-privacidad ml-3" style="cursor:pointer;"><a @click="openModal">AVISO DE PRIVACIDAD</a></p>
                    <!-- <p class="call-footer ml-3"><a href="#">Aviso de Privacidad</a> <br> <a href="#"> Leer documento</a></p> -->
                  </div>
              </div>
          </div>

          <div class="col-12 col-lg-4">
              <p class="siguenos-footer">
                  Síguenos en nuestras redes sociales
              </p>
              <ul class="redes-lista">
                  <li>
                      <a target="_blank" class="footer-correo" title="Correo Grupo Industrial Aguila" href="mailto:info@giaguila.com.mx">
                        <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'envelope' }" class="icon"/>
                      </a>
                  </li>
                  <li>
                      <a target="_blank" class="footer-facebook" title="Facebook Grupo Industrial Aguila" href="https://www.facebook.com/GIndAguila/">
                        <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook-f' }" class="icon"/>
                      </a>
                  </li>
                  <li>
                      <a target="_blank" class="footer-linkedin" title="LinkedIn Grupo Industrial Aguila" href="https://www.linkedin.com/company/giaguila/">
                        <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'linkedin-in' }" class="icon"/>
                      </a>
                  </li>
                  <li>
                      <a target="_blank" class="footer-instagram" title="Instagram Grupo Industrial Aguila" href="https://www.instagram.com/gindaguila/">
                        <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'instagram' }" class="icon"/>
                      </a>
                  </li>
                  <li>
                      <a target="_blank" class="footer-youtube" title="Youtube Grupo Industrial Aguila" href="https://www.youtube.com/channel/UCIlqMy8lV-8QheewOEDHE6A">
                        <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'youtube' }" class="icon"/>
                      </a>
                  </li>
              </ul>
          </div>
      </div>

      <!-- <div class="row">
          <div class="col-12 text-center">

              <p class="titulo-aviso-privacidad">AVISO DE PRIVACIDAD</p>
              <p class="info-aviso-privacidad">
                  Todos los derechos reservados © 2022
              </p>
          </div>
      </div> -->

      <b-modal
        id="bv-modal-aviso" size="xl" centered hide-footer content-class="site-modal modal-viewer"
        :header-text-variant="'light'"
        scrollable>
        <template #modal-title>AVISO DE PRIVACIDAD</template>
          <b-card class="bg-light">
                <b-card-text class="b-text">
                  La presente Política de Privacidad establece los términos en que, nuestra organización, Grupo Industrial Águila usa y protege la información
                  que es proporcionada por nuestros proveedores (usuarios) al momento de utilizar este portal web (https://proveedores.giaguila.com.mx).
                  Estamos comprometidos en resguardar y proteger la información aquí proporcionada asegurando que sólo se empleará de acuerdo con los términos de este documento.
                  Estamos altamente comprometidos para cumplir con el compromiso de mantener su información segura, usado sistemas avanzados y actualizados constantemente para
                  asegurarnos que no exista ningún acceso no autorizado. Sin embargo, esta Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo que le recomendamos
                  y enfatizamos revisar continuamente esta página para asegurarse que está de acuerdo con dichos cambios.
                </b-card-text>
          </b-card>
          <b-card class="bg-light mt-3">
            <b-card-sub-title class="mb-3 text-sub">Tipo de información recabada</b-card-sub-title>
            <b-card-text class="b-text">
              Nuestro sitio web podrá recoger información personal, por ejemplo: Nombre, información de contacto como su dirección de correo electrónico e información demográfica.
              Así mismo cuando sea necesario podrá ser requerida información específica para validar sus datos fiscales en cumplimiento con los requerimientos de la autoridad fiscal pertinente.
            </b-card-text>
          </b-card>
          <b-card class="bg-light mt-3">
            <b-card-sub-title class="mb-3 text-sub">Uso de la información recabada</b-card-sub-title>
            <b-card-text class="b-text">
              Nuestro sitio web emplea la información con el fin de proporcionar el mejor servicio posible, particularmente para mantener un registro de usuarios y un expediente electrónico
              de proveedores en caso que aplique.  Es posible que sean enviados correos electrónicos periódicamente a través de nuestro sitio con avisos relevantes para usted o que pueda brindarle
              algún beneficio, estos correos electrónicos serán enviados a la dirección que usted proporcione y podrán ser cancelados en cualquier momento.
            </b-card-text>
          </b-card>
          <b-card class="bg-light mt-3">
            <b-card-sub-title class="mb-3 text-sub">Cookies</b-card-sub-title>
            <b-card-text class="b-text">
              Una cookie se refiere a un fichero que es enviado con la finalidad de solicitar permiso para almacenarse en su ordenador, al aceptar dicho fichero se crea y la cookie sirve entonces
              para tener información respecto al tráfico web, y también facilita las futuras visitas a una web recurrente. Otra función que tienen las cookies es que con ellas las web pueden reconocerte
              individualmente y por tanto brindarte el mejor servicio personalizado de su web.
            </b-card-text>
            <b-card-text class="b-text">
              Nuestro sitio web emplea las cookies para poder identificar las páginas que son visitadas y su frecuencia. Esta información es empleada únicamente para análisis estadístico y después se elimina de forma permanente.
              Usted puede eliminar las cookies en cualquier momento desde su ordenador, sin embargo, éstas ayudan a proporcionar un mejor servicio de los sitios web y no dan acceso a información de
              su ordenador o de usted, a menos de que usted así lo quiera y lo proporcione directamente.
            </b-card-text>
            <b-card-text class="b-text">
              Usted puede aceptar o negar el uso de cookies, sin embargo, la mayoría de los navegadores aceptan cookies automáticamente para tener un mejor desempeño mientras explora un sitio web.
              También usted puede cambiar la configuración de su ordenador para declinar las cookies, sin embargo, si se declinan es posible que no pueda utilizar algunos de nuestros servicios.
            </b-card-text>
          </b-card>
          <b-card class="bg-light mt-3">
            <b-card-sub-title class="mb-3 text-sub">Enlaces a Terceros</b-card-sub-title>
            <b-card-text class="b-text">
              Este sitio web pudiera contener enlaces a otros sitios que pudieran ser de su interés. Una vez que usted dé clic en estos enlaces y abandone nuestra página, ya no tenemos control
              sobre el sitio al que es redirigido y por lo tanto no somos responsables de la protección o el manejo de su información. Dichos sitios están sujetos a sus propias políticas de privacidad,
              por lo cual, es recomendable que las consulte para confirmar que usted está de acuerdo con estas.
            </b-card-text>
          </b-card>
          <b-card class="bg-light mt-3">
            <b-card-sub-title class="mb-3 text-sub">Control de su información personal</b-card-sub-title>
            <b-card-text class="b-text">
              En cualquier momento usted puede restringir la recopilación o el uso de la información personal que es proporcionada a nuestro sitio web.
              Grupo Industrial Águila no cederá ni distribuirá la información personal que es recopilada sin su consentimiento, salvo que sea requerido por un juez con una orden judicial.
              Grupo Industrial Águila se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier momento.
            </b-card-text>
          </b-card>
      </b-modal>
  </div>
</footer>
</template>
<script>
import { version } from '../../package.json';

export default {
  data() {
    return {
      applicationVersion: version,
    };
  },
  methods: {
    openModal() {
      this.$bvModal.show('bv-modal-aviso');
    },
  },
};
</script>
<style lang="scss" scoped>
.b-text {
  text-align:justify;
  text-justify: inter-word;
}
.text-sub {
  color: black!important;
}
</style>
