/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Vuex from 'vuex';
import store from 'store';

Vue.use(Vuex);

const STORED_AGGRIDS = (storedAggrid) => {
  const aggridcols = {};
  Object.keys(storedAggrid).forEach((key) => {
    const item = store.get(`app.aggrid.${key}`);
    aggridcols[key] = typeof item !== 'undefined' ? item : storedAggrid[key];
  });
  return aggridcols;
};

export default {
  state: {
    ...STORED_AGGRIDS({
      agDashboardState: [],
      agCfdisState: [],
      agUsuariosState: [],
      agUsuariosProveedoresState: [],
      agCorreosAutState: [],
      agBitacoraState: [],
    }),
  },
  mutations: {
    SAVE_AGGRIDSTATE(state, payload) {
      window.localStorage.setItem(`app.aggrid.${payload.tblname}`, JSON.stringify(payload.columnstate));
      state[payload.tblname] = payload.columnstate;
    },
  },
  actions: {},
  getters: {
    stored_aggrids: (state) => state,
  },
};
